import { Project } from "./dataTypes";

// Project Images
import portfolioImg from "../assets/img/portfolio.png";
import carRacingGameImg from "../assets/img/Car-Racing-Game-img.png";
import codingChallengesImg from "../assets/img/coding-challenges.jpg";
import igralishte from "../assets/img/Igralishte-img.png";
import kabelKonfektionBanner from "../assets/img/kabelkonfektion_banner.png";
import braunConnectivitySolutions from "../assets/img/braun_connectivity.png";
import akrotectBanner from "../assets/img/akrotect_banner.png";
import gedankenSchmiedeBanner from "../assets/img/gedanken_banner.png";
import haroBanner from "../assets/img/haro_banner.png";
import abaddonBanner from "../assets/img/abbadon_banner.png";

// Social Media Icons
import linkedinIcon from "../assets/img/linkedin.svg";
import githubIcon from "../assets/img/github.svg";
import instagramIcon from "../assets/img/instagram.svg";

// Skill Icons
import javascriptIcon from "../assets/img/javascript-logo.png";
import typescriptIcon from "../assets/img/typescript-logo.jpg";
import reactIcon from "../assets/img/React-icon.png";
import cssIcon from "../assets/img/css-logo.png";
import htmlIcon from "../assets/img/html-logo.png";
import sassIcon from "../assets/img/sass-logo.png";
import bootstrapIcon from "../assets/img/bootstrap-logo.png";
import tailwindIcon from "../assets/img/tailwind-logo.png";
import jqueryIcon from "../assets/img/jquery-logo.png";
import gitIcon from "../assets/img/git-logo.png";
import nextjsIcon from "../assets/img/next-js-logo.svg";
import uiuxIcon from "../assets/img/ui-ux.png";

export const projects: Project[] = [
  {
    title: "Akrotect Baumaschinen GmbH",
    description: "Tech Stack: Next.js | Typescript | Sass",
    descriptionTwo:
      "Independent development and design from the ground up, utilizing Next.js, SASS, and TypeScript",
    imgUrl: akrotectBanner,
    btnText1: "Website",
    website: "https://www.akrotect-baumaschinen.com/",
    github: "https://www.akrotect-baumaschinen.com/",
  },
  {
    title: "Abaddon Mysticstore",
    description: "Tech Stack: Next.js | TypeScript | Sass | Shopware",
    descriptionTwo:
      "I was involved in rebuilding the shop with Next.js/Shopware to optimize performance.",
    imgUrl: abaddonBanner,
    btnText1: "Website",
    website: "https://www.abaddon-mysticstore.de/",
  },
  {
    title: "Kabelkonfektion",
    description: "Tech Stack: JavaScript | PHP | Sass | Processwire",
    descriptionTwo: "Responsible for the Frontend development.",
    imgUrl: kabelKonfektionBanner,
    btnText1: "Website",
    website: "https://kabelkonfektion.com/",
  },
  {
    title: "Braun Connectivity Solutions",
    description: "Tech Stack: JavaScript | PHP | Sass | Processwire",
    descriptionTwo:
      "Implemented new sites such as the careers page and online application.",
    imgUrl: braunConnectivitySolutions,
    btnText1: "Website",
    website: "https://www.braun-connectivity-solutions.com/",
  },
  {
    title: "Gedanken Schmiede GmbH",
    description: "Tech Stack: JavaScript | PHP | Sass | Processwire",
    descriptionTwo: "Redesign of the homepage and services section.",
    imgUrl: gedankenSchmiedeBanner,
    btnText1: "Website",
    website: "https://www.gedanken-schmiede.com/",
  },
  {
    title: "Haro",
    description: "Tech Stack: JavaScript | PHP | Laravel | Sass | Processwire",
    descriptionTwo:
      "I developed the action page, implementing several features.",
    imgUrl: haroBanner,
    btnText1: "Website",
    website: "https://www.haro.com/de/",
  },
  {
    title: "Igralishte - Online Clothing Store (Mobile - App)",
    description: "Tech Stack: React | Typescript | Sass",
    imgUrl: igralishte,
    btnText1: "Website",
    btnText2: "Github",
    website: "https://igralishte-kappa.vercel.app/",
    github: "https://github.com/Shkurtevski/igralishte",
  },
  {
    title: "This Portfolio",
    description: "Tech Stack: React | Typescript | CSS | Bootstrap",
    imgUrl: portfolioImg,
    btnText1: "Website",
    btnText2: "Github",
    website: "https://shkurtevski.dev/",
    github: "https://github.com/Shkurtevski/MyPortfolio",
  },
  {
    title: "Car Racing Game",
    description: "Tech Stack: Javascript | jQuery | Bootstrap | CSS",
    imgUrl: carRacingGameImg,
    btnText1: "Website",
    btnText2: "Github",
    website: "https://shkurtevski.github.io/Car-Racing-Game/",
    github: "https://github.com/Shkurtevski/Car-Racing-Game",
  },
  {
    title: "Various Coding Challenges",
    description:
      "Tech Stack: HTML | CSS | Sass | Javascript | React | Next.js | jQuery",
    imgUrl: codingChallengesImg,
    btnText1: "Github",
    website: "https://github.com/Shkurtevski/CodingChallenges",
    github: "https://github.com/Shkurtevski/CodingChallenges",
  },
];

export const socialLinksData = [
  {
    href: "https://www.linkedin.com/in/nenad-shkurtevski-b49b20250/",
    imgSrc: linkedinIcon,
    altText: "LinkedIn",
  },
  {
    href: "https://github.com/Shkurtevski",
    imgSrc: githubIcon,
    altText: "GitHub",
  },
  {
    href: "https://www.instagram.com/nenad.shkurtevski/",
    imgSrc: instagramIcon,
    altText: "Instagram",
  },
];

export const navLinks = [
  { to: "/#home", text: "Home" },
  { to: "/#skills", text: "Skills" },
  { to: "/#projects", text: "Projects" },
];

export const bannerText: string[] = [
  "Software Developer.",
  "Frontend Developer.",
  "React Developer.",
];

export const skillsData = [
  {
    id: 1,
    icon: javascriptIcon,
    title: "JavaScript",
  },
  {
    id: 2,
    icon: typescriptIcon,
    title: "Typescript",
  },
  {
    id: 3,
    icon: reactIcon,
    title: "React",
  },
  {
    id: 4,
    icon: nextjsIcon,
    title: "Next.js",
  },
  {
    id: 5,
    icon: cssIcon,
    title: "CSS",
  },
  {
    id: 6,
    icon: htmlIcon,
    title: "HTML",
  },
  {
    id: 7,
    icon: sassIcon,
    title: "Sass",
  },
  {
    id: 8,
    icon: jqueryIcon,
    title: "jQuery",
  },
  {
    id: 9,
    icon: bootstrapIcon,
    title: "Bootstrap",
  },
  {
    id: 10,
    icon: tailwindIcon,
    title: "Tailwind CSS",
  },
  {
    id: 11,
    icon: gitIcon,
    title: "git",
  },
  {
    id: 12,
    icon: uiuxIcon,
    title: "UI/UX",
  },
];
